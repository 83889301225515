import { Camera } from "lucide-react";
import React, { useState } from "react";

import { clsxm } from "@peykio/clsxm";

import {
  Avatar,
  AvatarImage,
  Button,
  FormControl,
  FormField,
  FormItem,
} from "@dewrangle/ui";

interface LogoUploadProps {
  initialImage?: string | null;
  onImageChange: (image: string | null) => void;
}

export const LogoUpload = ({
  onImageChange,
  initialImage,
}: LogoUploadProps) => {
  const [image, setImage] = useState(initialImage);
  const [imageError, setImageError] = useState("");

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageError("");
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      if (file.size > 128 * 1024) {
        setImageError("Image size should not exceed 128KB.");
        setImage(null);
        onImageChange(null);
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          setImage(reader.result);
          onImageChange(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <FormField
        name="image"
        render={() => (
          <FormItem className="mb-2">
            <FormControl>
              <label htmlFor="logo-upload" className="cursor-pointer">
                <Avatar className="relative w-28 h-28 border bg-secondary">
                  <AvatarImage
                    src={image || undefined}
                    alt="organization logo"
                    className="w-full h-full object-cover rounded-full"
                  />
                  <input
                    id="logo-upload"
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={handleImageChange}
                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                  />
                  <div
                    className={clsxm([
                      "absolute inset-0 flex flex-col justify-center items-center bg-black/50",
                      image ? "opacity-0 hover:opacity-100" : "opacity-100",
                    ])}
                  >
                    <Camera className="text-primary-foreground" size={28} />
                    <span className="text-xs text-primary-foreground mt-2">
                      Max 128KB
                    </span>
                  </div>
                </Avatar>
              </label>
            </FormControl>
          </FormItem>
        )}
      />
      {image && (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => {
            setImage(null);
            onImageChange(null);
          }}
        >
          Remove
        </Button>
      )}
      {imageError && (
        <span className="text-sm text-destructive text-center">
          Exceeds 128KB
        </span>
      )}
    </>
  );
};
