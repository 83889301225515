import { zodResolver } from "@hookform/resolvers/zod";
import { Plus } from "lucide-react";
import { useRouter } from "next/router";
import { ComponentPropsWithoutRef, useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { graphql, useFragment, useMutation } from "react-relay";
import * as z from "zod";

import { clsxm } from "@peykio/clsxm";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  ButtonProps,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from "@dewrangle/ui";

import {
  ActionButton,
  ActionButtonProps,
  ActionButtonStateHandler,
  FormErrors,
  Layout,
  type PayloadOrMutationErrors,
} from "@/components/uikit";
import { useUserConnectionIds } from "@/hooks/useConnectionIds";
import { replaceEmptyStringWithNull } from "@/lib/object";
import type {
  Forms_createOrganizationMutation,
  OrganizationVisibility,
} from "@/queries/__generated__/Forms_createOrganizationMutation.graphql";
import type { Forms_deleteOrganizationMutation } from "@/queries/__generated__/Forms_deleteOrganizationMutation.graphql";
import type { Forms_deleteOrganizationQuery$key } from "@/queries/__generated__/Forms_deleteOrganizationQuery.graphql";
import { Forms_organizationCreateQuery$key } from "@/queries/__generated__/Forms_organizationCreateQuery.graphql";
import type { Forms_updateOrganizationMutation } from "@/queries/__generated__/Forms_updateOrganizationMutation.graphql";
import type { Forms_updateOrganizationQuery$key } from "@/queries/__generated__/Forms_updateOrganizationQuery.graphql";

import { LogoUpload } from "./LogoUpload";

const useOrganizationCreateFragment = ({
  queryRef,
}: {
  queryRef: Forms_organizationCreateQuery$key;
}) => {
  return useFragment(
    graphql`
      fragment Forms_organizationCreateQuery on User {
        id
      }
    `,
    queryRef
  );
};

export const useOrganizationCreateMutation = () => {
  return useMutation<Forms_createOrganizationMutation>(graphql`
    mutation Forms_createOrganizationMutation(
      $input: OrganizationCreateInput!
      $connections: [ID!]!
    ) {
      organizationCreate(input: $input) {
        organization {
          id
          name
          organizationUser
            @appendNode(
              connections: $connections
              edgeTypeName: "UserOrganizationUsersConnectionEdge"
            ) {
            organization {
              ...MemberOrganization_organizationCardQuery
            }
          }
        }
        errors {
          ... on MutationError {
            message
          }
        }
      }
    }
  `);
};

export const OrganizationCreateForm = ({
  queryRef,
  onStateChange,
  className = "",
  ...rest
}: {
  onStateChange: ActionButtonStateHandler;
  queryRef: Forms_organizationCreateQuery$key;
} & ComponentPropsWithoutRef<"form">) => {
  const formSchema = z.object({
    name: z.string(),
    description: z.string(),
    email: z.string(),
    website: z.string(),
    visibility: z.enum(["OPENLYLISTED", "PRIVATE"]),
    image: z.string().nullable(),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      description: "",
      email: "",
      website: "",
      visibility: "PRIVATE",
      image: null,
    },
  });

  const router = useRouter();
  const query = useOrganizationCreateFragment({ queryRef });
  const { organizationUsersConnectionId } = useUserConnectionIds();
  const [commit, isInFlight] = useOrganizationCreateMutation();
  const [graphqlError, setGraphqlError] =
    useState<PayloadOrMutationErrors>(null);

  useEffect(() => {
    if (isInFlight) {
      onStateChange("loading");
    }
  }, [isInFlight, onStateChange]);

  return (
    <Form {...form}>
      <form
        id="create-organization-form"
        onChange={() => {
          onStateChange("idle");
          setGraphqlError(null);
        }}
        onSubmit={form.handleSubmit((formData, e) => {
          if (e?.target.id !== "create-organization-form") {
            return;
          }
          commit({
            variables: {
              input: replaceEmptyStringWithNull(formData),
              connections: [organizationUsersConnectionId(query.id)],
            },
            onCompleted: (res, error) => {
              const errors = error || res?.organizationCreate?.errors;
              if (errors && errors?.length > 0) {
                onStateChange("error");
                setGraphqlError(errors);
              } else {
                onStateChange("success");
                if (res.organizationCreate?.organization) {
                  void router.push({
                    pathname: `/[organization]`,
                    query: {
                      organization: res.organizationCreate?.organization?.id,
                    },
                  });
                }
              }
            },
            onError: (error) => {
              onStateChange("error");
              setGraphqlError([error]);
            },
          });
        })}
        className={clsxm("space-y-4", {
          [className]: className.length,
        })}
        {...rest}
      >
        <Layout.Row className="items-center">
          <div className="w-1/4 flex flex-col justify-center content-start">
            <LogoUpload
              onImageChange={(value) =>
                form.setValue("image", value, { shouldValidate: true })
              }
            />
          </div>
          <div className="w-3/4">
            <FormField
              name="name"
              control={form.control}
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel htmlFor="organization-name" required>
                    Name
                  </FormLabel>
                  <FormControl>
                    <Input required id="organization-name" {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="email"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel htmlFor="organization-email">
                    Contact email
                  </FormLabel>
                  <FormControl>
                    <Input type="email" id="organization-email" {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </Layout.Row>
        <FormField
          name="description"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel htmlFor="organization-description">
                Description
              </FormLabel>
              <FormControl>
                <Textarea id="organization-description" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          name="website"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel htmlFor="organization-website">Website</FormLabel>
              <FormControl>
                <Input id="organization-website" type="url" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          name="visibility"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel htmlFor="organization-visibility">
                Visibility
              </FormLabel>
              <FormControl>
                <Select
                  defaultValue={field.value}
                  onValueChange={(value: OrganizationVisibility) =>
                    form.setValue(field.name, value, { shouldValidate: true })
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select Organization Visibility" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={"PRIVATE"}>Private</SelectItem>
                    <SelectItem value={"OPENLYLISTED"}>
                      Openly Listed
                    </SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
            </FormItem>
          )}
        />
        <FormErrors errors={graphqlError} />
      </form>
    </Form>
  );
};

export const OrganizationCreateDialog = ({
  queryRef,
  buttonProps = {},
}: {
  queryRef: Forms_organizationCreateQuery$key;
  buttonProps?: ButtonProps;
}) => {
  const [buttonState, setButtonState] =
    useState<ActionButtonProps["state"]>("idle");

  return (
    <AlertDialog onOpenChange={() => setButtonState("idle")}>
      <AlertDialogTrigger asChild>
        <Button
          id="create-organization-dialog-trigger"
          size="sm"
          variant="ghost"
          {...buttonProps}
        >
          <Plus size={16} />
          New Organization
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Create New Organization</AlertDialogTitle>
          <OrganizationCreateForm
            queryRef={queryRef}
            onStateChange={(state) => setButtonState(state)}
          />
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <ActionButton
            state={buttonState}
            id="create-organization-submit-button"
            type="submit"
            form="create-organization-form"
            variant="primary"
            size="sm"
          >
            Create
          </ActionButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export const useOrganizationUpdateMutation = () => {
  return useMutation<Forms_updateOrganizationMutation>(graphql`
    mutation Forms_updateOrganizationMutation(
      $id: ID!
      $input: OrganizationUpdateInput!
    ) {
      organizationUpdate(id: $id, input: $input) {
        organization {
          ...Forms_updateOrganizationQuery
        }
        errors {
          ... on MutationError {
            message
          }
        }
      }
    }
  `);
};

export const useOrganizationUpdateFragment = (
  queryRef: Forms_updateOrganizationQuery$key
) => {
  return useFragment(
    graphql`
      fragment Forms_updateOrganizationQuery on Organization {
        id
        name
        description
        email
        website
        visibility
        image
      }
    `,
    queryRef
  );
};

export const OrganizationUpdateForm = ({
  onStateChange,
  queryRef,
  className = "",
  ...rest
}: {
  onStateChange: ActionButtonStateHandler;
  queryRef: Forms_updateOrganizationQuery$key;
} & ComponentPropsWithoutRef<"form">) => {
  const query = useOrganizationUpdateFragment(queryRef);
  const formSchema = z.object({
    name: z.string(),
    description: z.string(),
    email: z.string(),
    website: z.string(),
    visibility: z.enum(["OPENLYLISTED", "PRIVATE"]),
    image: z.string().nullable(),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: query.name,
      description: query.description ?? "",
      email: query.email ?? "",
      website: query.website ?? "",
      visibility: query.visibility,
      image: query.image,
    },
  });
  const [commit, isInFlight] = useOrganizationUpdateMutation();
  const [graphqlError, setGraphqlError] =
    useState<PayloadOrMutationErrors>(null);

  useEffect(() => {
    if (isInFlight) {
      onStateChange("loading");
    }
  }, [isInFlight, onStateChange]);

  return (
    <Form {...form}>
      <form
        id="update-organization-form"
        onChange={() => {
          onStateChange("idle");
          setGraphqlError(null);
        }}
        onSubmit={form.handleSubmit((formData, e) => {
          if (e?.target.id !== "update-organization-form") {
            return;
          }
          commit({
            variables: {
              input: replaceEmptyStringWithNull(formData),
              id: query.id,
            },
            onCompleted: (res, error) => {
              const errors = error || res?.organizationUpdate?.errors;
              if (errors && errors?.length > 0) {
                onStateChange("error");
                setGraphqlError(errors);
              } else {
                onStateChange("success");
              }
            },
            onError: (error) => {
              onStateChange("error");
              setGraphqlError([error]);
            },
          });
        })}
        className={clsxm("space-y-4", {
          [className]: className.length,
        })}
        {...rest}
      >
        <Layout.Row className="items-center gap-0">
          <div className="w-3/4">
            <FormField
              name="name"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel htmlFor="organization-name" required>
                    Name
                  </FormLabel>
                  <FormControl>
                    <Input required id="organization-name" {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="description"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel htmlFor="organization-description">
                    Description
                  </FormLabel>
                  <FormControl>
                    <Textarea id="organization-description" {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <div className="w-1/4 pr-6 pl-20 flex flex-col items-center">
            <FormField
              name="image"
              control={form.control}
              render={({ field }) => (
                <LogoUpload
                  initialImage={field.value}
                  onImageChange={(value) =>
                    form.setValue("image", value, { shouldValidate: true })
                  }
                />
              )}
            />
          </div>
        </Layout.Row>
        <FormField
          name="email"
          control={form.control}
          render={({ field }) => (
            <FormItem className="w-3/4">
              <FormLabel htmlFor="organization-email">Contact email</FormLabel>
              <FormControl>
                <Input id="organization-email" type="email" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          name="website"
          control={form.control}
          render={({ field }) => (
            <FormItem className="w-3/4">
              <FormLabel htmlFor="organization-website">Website</FormLabel>
              <FormControl>
                <Input type="url" id="organization-website" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          name="visibility"
          control={form.control}
          render={({ field }) => (
            <FormItem className="w-3/4">
              <FormLabel htmlFor="organization-visibility">
                Visibility
              </FormLabel>
              <FormControl>
                <Select
                  defaultValue={field.value}
                  onValueChange={(value: OrganizationVisibility) =>
                    form.setValue("visibility", value, { shouldValidate: true })
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select Organization Visibility" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={"PRIVATE"}>Private</SelectItem>
                    <SelectItem value={"OPENLYLISTED"}>
                      Openly Listed
                    </SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
            </FormItem>
          )}
        />
        <FormErrors errors={graphqlError} />
      </form>
    </Form>
  );
};

export const OrganizationUpdateFormInline = ({
  queryRef,
}: {
  queryRef: Forms_updateOrganizationQuery$key;
}) => {
  const [buttonState, setButtonState] =
    useState<ActionButtonProps["state"]>("idle");

  useEffect(() => {
    if (["error", "success"].includes(buttonState)) {
      const timer = setTimeout(() => {
        setButtonState("idle");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [buttonState]);

  return (
    <Layout.Column>
      <OrganizationUpdateForm
        queryRef={queryRef}
        onStateChange={(state) => setButtonState(state)}
      />
      <Layout.Row>
        <ActionButton
          state={buttonState}
          name="save"
          type="submit"
          form="update-organization-form"
          variant="primary"
          aria-label="Update Organization"
        >
          Save
        </ActionButton>
      </Layout.Row>
    </Layout.Column>
  );
};

export const useOrganizationDeleteMutation = () => {
  return useMutation<Forms_deleteOrganizationMutation>(graphql`
    mutation Forms_deleteOrganizationMutation($id: ID!, $connections: [ID!]!) {
      organizationDelete(id: $id) {
        organization {
          id @deleteEdge(connections: $connections)
          name
        }
      }
    }
  `);
};

export const useOrganizationDeleteFragment = (
  queryRef: Forms_deleteOrganizationQuery$key
) => {
  return useFragment(
    graphql`
      fragment Forms_deleteOrganizationQuery on Organization {
        id
        name
        organizationUser {
          user {
            id
          }
        }
      }
    `,
    queryRef
  );
};

export const OrganizationDeleteForm = ({
  onStateChange,
  onFormChange,
  queryRef,
  className = "",
  ...rest
}: {
  onStateChange: ActionButtonStateHandler;
  onFormChange: (_: { isValid: boolean }) => void;
  queryRef: Forms_deleteOrganizationQuery$key;
} & ComponentPropsWithoutRef<"form">) => {
  const query = useOrganizationDeleteFragment(queryRef);

  const formSchema = z.object({
    deleteNameCheck: z.string().refine((value) => value === query.name),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      deleteNameCheck: "",
    },
  });
  const { isValid } = useFormState({ control: form.control });
  useEffect(() => onFormChange({ isValid }), [onFormChange, isValid]);

  const router = useRouter();
  const { organizationUsersConnectionId } = useUserConnectionIds();
  const [commit, isInFlight] = useOrganizationDeleteMutation();
  const [graphqlError, setGraphqlError] =
    useState<PayloadOrMutationErrors>(null);

  useEffect(() => {
    if (isInFlight) {
      onStateChange("loading");
    }
  }, [isInFlight, onStateChange]);

  return (
    <Form {...form}>
      <form
        id="delete-organization-form"
        onSubmit={(e) => {
          e.preventDefault();
          commit({
            variables: {
              id: query.id,
              connections: [
                organizationUsersConnectionId(query.organizationUser?.user.id),
              ],
            },
            onCompleted: () => {
              onStateChange("success");
              void router.push("/");
            },
            onError: (error) => {
              onStateChange("error");
              setGraphqlError([error]);
            },
          });
        }}
        className={clsxm("space-y-4", {
          [className]: className.length,
        })}
        {...rest}
      >
        <FormField
          name="deleteNameCheck"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel htmlFor="organization-delete-name-check" required>
                Please type <span className="font-bold">{query.name}</span> to
                confirm.
              </FormLabel>
              <FormControl>
                <Input
                  required
                  autoFocus
                  id="organization-delete-name-check"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormErrors errors={graphqlError} />
      </form>
    </Form>
  );
};

export const OrganizationDeleteDialog = ({
  queryRef,
}: {
  queryRef: Forms_deleteOrganizationQuery$key;
}) => {
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [buttonState, setButtonState] =
    useState<ActionButtonProps["state"]>("idle");

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="destructive" size="sm">
          Delete this organization
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            Once you delete a organization, there is no going back. Please be
            certain.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <OrganizationDeleteForm
          queryRef={queryRef}
          onStateChange={setButtonState}
          onFormChange={({ isValid }) => setSubmitDisabled(!isValid)}
        />
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <ActionButton
            state={buttonState}
            type="submit"
            form="delete-organization-form"
            variant="destructive"
            disabled={submitDisabled}
            size="sm"
          >
            Delete this organization
          </ActionButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
