/**
 * @generated SignedSource<<29fb5078cd37fa54f7cb1f62a3d7a565>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Forms_deleteOrganizationMutation$variables = {
  connections: ReadonlyArray<string>;
  id: string;
};
export type Forms_deleteOrganizationMutation$data = {
  readonly organizationDelete: {
    readonly organization: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
  };
};
export type Forms_deleteOrganizationMutation = {
  response: Forms_deleteOrganizationMutation$data;
  variables: Forms_deleteOrganizationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Forms_deleteOrganizationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "OrganizationDeletePayload",
        "kind": "LinkedField",
        "name": "organizationDelete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Forms_deleteOrganizationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "OrganizationDeletePayload",
        "kind": "LinkedField",
        "name": "organizationDelete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "19a93a491c9001c411d0b22b67ea4f4d",
    "id": null,
    "metadata": {},
    "name": "Forms_deleteOrganizationMutation",
    "operationKind": "mutation",
    "text": "mutation Forms_deleteOrganizationMutation(\n  $id: ID!\n) {\n  organizationDelete(id: $id) {\n    organization {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4ba8e368d5f019c842b9ed2eb82ab055";

export default node;
